import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import {
  RootState,
  updateCurrentNft,
  updateCurrentRoute,
} from "../../../../rx.core";
import { useFetchNfts } from "../../../../../hooks";
import NftAssets from "../../components/LoadersPages/NftAssets";
import { NftCard } from "../../components/Cards/NftCard";

export default function Nft() {
  const { loading } = useFetchNfts();
  const nftItems = useSelector((store: RootState) => store.User?.userNfts);
  const [triaName, setTriaName] = useState<string>();
  const dispatch = useDispatch();

  dispatch(updateCurrentRoute(window.location.pathname));

  useEffect(() => {
    const storedData = localStorage.getItem("tria.wallet.store.app");
    if (storedData !== null) {
      const parsedData = JSON.parse(storedData);
      setTriaName(parsedData?.triaName);
    } else {
      console.log("Can't find triaName;");
    }
  }, []);
  const navigate = useNavigate();

  const handleNftItemClick = (index: number) => {
    const selected = nftItems[index];
    dispatch(
      updateCurrentNft({
        chainName: selected?.chainName,
        tokenAddress: selected?.nftInfo?.contract_address,
        tokenId: selected?.nftInfo?.token_id,
        triaName: triaName || "",
        userAddress: selected?.wallet?.address,
        addressType: selected?.wallet?.type,
        amount: selected?.amount,
      })
    );
    navigate("/nfts/oneNft");
  };

  return (
    <div className="flex flex-grow w-full h-full">
      {loading && <NftAssets />}
      {!loading && nftItems?.length > 0 && (
        <div className="overflow-y-scroll w-full h-full grid grid-cols-2 scrollbar-thin gap-2 px-3 scrollbar-thumb-gray-300 scrollbar-track-stone-100">
          {nftItems?.map((nftItem, index) => (
            <NftCard
              key={index}
              id={index}
              loading={loading}
              nftItem={nftItem}
              onClick={() => {}}
              responsive={true}
            />
          ))}
        </div>
      )}

      {!loading && !nftItems?.length && (
        <div className="flex flex-col gap-[1vh] items-center justify-center w-full h-[38vh]">
          <img
            src="./images/nonftimagedark.svg"
            className="w-[17.8vh] h-[17.8vh]"
            alt="nonft"
          />
          <p className="font-[600] text-[1.4vh] text-fontLightColor dark:text-fontLightColorDark">
            You have no NFTs right now
          </p>
        </div>
      )}
    </div>
  );
}
