import { nativeTokenDetails } from "@tria-sdk/connect";

export enum ChainValue {
  POLYGON = "POLYGON",
  ETH = "ETH",
  OPTIMISM = "OPTIMISM",
  ARBITRUM = "ARBITRUM",
  BINANCE = "BINANCE",
  AVALANCHE = "AVALANCHE",
  SKYNET = "SKYNET",
}

export const zeroAddress = "0x0000000000000000000000000000000000000000";

export const ZeroAddressMap: Record<string, string> = {
  [`${ChainValue.POLYGON}_${nativeTokenDetails.POLYGON.symbol}`]: zeroAddress,
  [`${ChainValue.ETH}_${nativeTokenDetails.ETH.symbol}`]: zeroAddress,
  [`${ChainValue.OPTIMISM}_${nativeTokenDetails.OPTIMISM.symbol}`]: zeroAddress,
  [`${ChainValue.ARBITRUM}_${nativeTokenDetails.ARBITRUM.symbol}`]: zeroAddress,
  [`${ChainValue.BINANCE}_${nativeTokenDetails.BINANCE.symbol}`]: zeroAddress,
  [`${ChainValue.AVALANCHE}_${nativeTokenDetails.AVALANCHE.symbol}`]:
    zeroAddress,
  [`${ChainValue.POLYGON}_USDC`]: "0x3c499c542cef5e3811e1192ce70d8cc03d5c3359",
  [`${ChainValue.ETH}_USDC`]: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
  [`${ChainValue.OPTIMISM}_USDC`]: "0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85",
  [`${ChainValue.ARBITRUM}_USDC`]: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
  [`${ChainValue.BINANCE}_USDC`]: "0x672147dD47674757C457eB155BAA382cc10705Dd",
  [`${ChainValue.AVALANCHE}_USDC`]:
    "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
  [`${ChainValue.POLYGON}_USDT`]: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
  [`${ChainValue.ETH}_USDT`]: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
  [`${ChainValue.OPTIMISM}_USDT`]: "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
  [`${ChainValue.ARBITRUM}_USDT`]: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
  [`${ChainValue.BINANCE}_USDT`]: "0x524bC91Dc82d6b90EF29F76A3ECAaBAffFD490Bc",
  [`${ChainValue.AVALANCHE}_USDT`]:
    "0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7",
  [`${ChainValue.SKYNET}_XCTERC20`]:
    "0xB9E1cF44b430626A9A344f25853Ef29B63DFD7c5",
};
