import { chainNameToLogo } from "@tria-sdk/constants";
import { CustomChainData } from "@tria-sdk/core";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useUserPrefs } from "../../../../../hooks";
import {
  ChainAddressData,
  NetworkItem,
  RootState,
  copyToClipboard,
  formatAddress,
  useToast,
} from "../../../../rx.core";
import { isCustomChain } from "../../layouts/Navbar/utils";
import { CopyButton } from "../Buttons";
import { QrButton } from "../Buttons/QrButton";
import QR from "./QR";

const ChainLogo: React.FC<{ chainName: string; logoUrl?: string }> = ({
  chainName,
  logoUrl,
}) => {
  if (logoUrl) {
    return (
      <img
        src={logoUrl}
        alt={chainName}
        className="w-[2.8vh] h-[2.8vh] rounded-[0.6vh]"
      />
    );
  }

  const initials = chainName
    .split(" ")
    .slice(0, 2)
    .map((word) => word[0])
    .join("")
    .toUpperCase();

  return (
    <div className="w-[2.8vh] h-[2.8vh] flex justify-center items-center text-[1.2vh] border-1 bg-[#a855f7] text-primaryColor rounded-[0.6vh]">
      {initials}
    </div>
  );
};

function isSameChain(
  ch1: string | CustomChainData,
  ch2: string | CustomChainData
): boolean {
  if (typeof ch1 !== typeof ch2) {
    return false;
  }
  if (
    typeof ch1 === "string" &&
    typeof ch2 === "string" &&
    ch1.toUpperCase() === ch2.toUpperCase()
  ) {
    return true;
  }
  if (isCustomChain(ch1) && isCustomChain(ch2)) {
    return ch1.chainId === ch2.chainId;
  }
  return false;
}

function findAddress(
  addr: ChainAddressData[],
  chain: string | CustomChainData
) {
  return addr.find((addr) => isSameChain(chain, addr.chainName));
}

interface Props {
  userAddresses: ChainAddressData[];
  userNonEvmAddresses: ChainAddressData[];
  supportedChains: (string | CustomChainData)[];
  priorityChains?: string[];
  lite?: boolean;
  qrHandler?: (value: boolean) => void;
  setAccountsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  accountsExpanded: boolean;
  evmAddress?: string;
}

export const Accounts: React.FC<Props> = ({
  userAddresses,
  userNonEvmAddresses,
  supportedChains,
  priorityChains = ["ETH", "SOLANA", "BINANCE"],
  lite = false,
  qrHandler,
  accountsExpanded,
  setAccountsExpanded,
  evmAddress,
}) => {
  const [showQr, setShowQr] = useState(false);
  const [qrData, setQrData] = useState({
    address: "",
    chain: undefined as NetworkItem | undefined,
  });
  const [copied, setCopied] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const navigate = useNavigate();

  const { setToastData, openToastMessage, closeToastMessage } = useToast();
  const networkItems = useSelector(
    (store: RootState) => store.Constants?.NetworkItems
  );
  const { data: userPrefs } = useUserPrefs();

  // Helper function to get chain display name
  const getChainDisplayName = (chain: ChainAddressData) =>
    isCustomChain(chain.chainName)
      ? chain.chainName.chainName
      : chain.chainName;

  // Step 1: Process all supported chains
  const allChains = useMemo(() => {
    const combinedAddresses = [...userAddresses, ...userNonEvmAddresses];

    return supportedChains
      .map((chain) => {
        const chainData = findAddress(combinedAddresses, chain);
        if (!chainData) return null;

        return {
          ...chainData,
          logo:
            chainNameToLogo[isCustomChain(chain) ? chain.chainName : chain] ||
            "",
          isCustomChain: isCustomChain(chain),
          isCustomChainApproved:
            !isCustomChain(chain) ||
            userPrefs?.customChains?.some(
              (c) => c.chainId.toString() === chain.chainId.toString()
            ),
        };
      })
      .filter(Boolean) as ChainAddressData[];
  }, [
    supportedChains,
    userAddresses,
    userNonEvmAddresses,
    userPrefs?.customChains,
  ]);

  // Step 2: Get unapproved custom chains
  const unapprovedCustomChains = useMemo(
    () =>
      allChains.filter(
        (chain) => chain.isCustomChain && !chain.isCustomChainApproved
      ),
    [allChains]
  );

  // Step 3: Sort chains by priority and type
  const sortedChains = useMemo(() => {
    // Get priority chains (excluding custom chains)
    const priorityChainsList = allChains.filter(
      (chain) =>
        !isCustomChain(chain.chainName) &&
        priorityChains.includes(chain.chainName.toUpperCase())
    );

    // Get remaining chains (excluding unapproved custom chains and priority chains)
    const otherChains = allChains.filter((chain) => {
      const chainName = isCustomChain(chain.chainName)
        ? chain.chainName.chainName
        : chain.chainName;

      return (
        (!isCustomChain(chain.chainName) || chain.isCustomChainApproved) &&
        !priorityChains.includes(chainName.toUpperCase())
      );
    });

    return [...unapprovedCustomChains, ...priorityChainsList, ...otherChains];
  }, [allChains, priorityChains, unapprovedCustomChains]);

  // Step 4: Determine which chains to display
  const displayedChains = useMemo(() => {
    // Search takes precedence over expansion state
    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      return sortedChains.filter((chain) =>
        getChainDisplayName(chain).toLowerCase().includes(searchLower)
      );
    }

    // If not expanded, show only unapproved custom chains and priority chains
    if (!accountsExpanded) {
      return sortedChains.slice(
        0,
        unapprovedCustomChains.length + priorityChains.length
      );
    }

    // Show all chains when expanded
    return sortedChains;
  }, [
    sortedChains,
    searchTerm,
    accountsExpanded,
    unapprovedCustomChains.length,
    priorityChains.length,
  ]);

  useEffect(() => {
    if (sortedChains.length > priorityChains.length) {
      setShowSearch(true);
    }
  }, [sortedChains, priorityChains.length]);

  const handleAddCustomChain = (chain: ChainAddressData) => () => {
    if (isCustomChain(chain.chainName)) {
      const approveParams = btoa(
        JSON.stringify({
          customChain: {
            chainId: chain.chainName.chainId,
            chainName: chain.chainName.chainName,
            rpcUrl: chain.chainName.rpcUrl,
            currencySymbol: chain.chainName.currencySymbol,
            currencyName: chain.chainName.currencyName,
            chainLogo: chain.chainName.chainLogo,
            nativeTokenLogo: chain.chainName.nativeTokenLogo,
          },
        })
      );
      navigate(`/approvecustomchain/${approveParams}?lite=${lite}`);
    }
  };

  const handleCopyClick = (address: string) => {
    const { copied, text } = copyToClipboard(address);
    setCopied(copied);
    if (copied) {
      setToastData({ title: "Copied!", desc: text, status: "message" });
      openToastMessage();
      setTimeout(() => {
        closeToastMessage();
        setCopied(false);
      }, 4000);
    }
  };

  const handleQrClick = (address: string, chain: NetworkItem | undefined) => {
    setShowQr(!showQr);
    setQrData({ address, chain });
    qrHandler?.(!showQr);
  };

  const renderChainItem = (chain: ChainAddressData) => {
    const isApproved = chain.isCustomChainApproved;

    return (
      <div
        key={chain.id}
        className={`${
          chain.isCustomChain && !isApproved
            ? "bg-yellow-100/40 dark:bg-yellow-100/10"
            : "bg-transparent"
        } self-stretch justify-start items-center gap-[2vh] inline-flex`}
        style={{
          borderRadius: "8px",
          padding: "8px",
        }}
      >
        <div className="flex gap-[0.5vh] items-center">
          <ChainLogo
            chainName={getChainDisplayName(chain)}
            logoUrl={chain.logo}
          />
        </div>
        <div className="grow shrink basis-0 flex-col justify-start items-start gap-[0.5vh] inline-flex">
          <div className="flex items-center">
            <div
              className={`${
                lite ? "text-sm" : "text-[2vh]"
              } text-left text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80 font-semibold leading-tight`}
            >
              {getChainDisplayName(chain)}
            </div>
            {chain.isAA && (
              <span className="px-1 py-0.5 rounded">
                <img
                  src="/icons/smart-wallet.svg"
                  className="w-4 h-4"
                  alt="Smart Wallet"
                />
              </span>
            )}
          </div>
          <div
            className={`${
              lite ? "text-xs" : "text-[1.67vh]"
            } text-center text-neutral-400 font-semibold leading-tight max-w-[24vh] overflow-hidden`}
          >
            {formatAddress(chain.address)}
          </div>
        </div>
        {chain.isCustomChain && !isApproved ? (
          <button
            onClick={handleAddCustomChain(chain)}
            className="px-3 py-1 rounded-full bg-primary text-black dark:text-white text-xxs font-semibold border-0.2 border-black/30 dark:border-white/30"
          >
            Add
          </button>
        ) : (
          <>
            <QrButton
              onClick={() =>
                handleQrClick(
                  chain.address,
                  networkItems.find(
                    (item) => item.chainName === chain.chainName
                  )
                )
              }
            />
            <CopyButton
              onClick={() => handleCopyClick(chain.address)}
              height="2.1vh"
              width="2.1vh"
            />
          </>
        )}
      </div>
    );
  };

  return (
    <div
      className={`min-w-full bg-primaryColor ${
        showQr ? "transform duration-200" : "h-[unset]"
      } rounded-lg ${
        lite
          ? "dark:bg-popoverBackgroundDark"
          : "dark:bg-primaryDarkColor max-h-[62.5vh]"
      }`}
    >
      {!showQr && showSearch && (
        <div className="px-4 pb-2 pt-4">
          <div className="text-sm text-black dark:text-white w-full text-center justify-center mb-4 font-semibold">
            All Networks
          </div>
          <input
            type="text"
            placeholder="Search"
            className="dark:text-white text-black text-sm w-full h-[4vh] rounded-full px-3 mb-2 bg-gray-300 dark:bg-black/10 focus:outline-none focus:border focus:border-neutral-600"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      )}

      {!showQr && (
        <>
          <div
            className={`max-h-[250px] overflow-y-scroll pl-2 pr-1 pt-4 flex-col justify-start gap-2 inline-flex min-w-full relative`}
          >
            {displayedChains.map(renderChainItem)}

            {copied && (
              <div className="absolute bg-primaryColor dark:bg-primaryDarkColor text-fontLightColor dark:text-fontLightColorDark flex items-center justify-center rounded-[25px] w-[8.3vh] h-[2.8vh] bottom-[-1.2vh] right-[1.2vh] text-[1.67vh]">
                Copied
              </div>
            )}
          </div>
          {sortedChains.length >
            unapprovedCustomChains.length + priorityChains.length &&
            !searchTerm && (
              <div
                className="text-sm w-full flex justify-center items-center px-2 pb-3 text-black/30 dark:text-white/80 underline cursor-pointer"
                onClick={() => setAccountsExpanded(!accountsExpanded)}
              >
                {accountsExpanded ? "Show less" : "Show more"}
              </div>
            )}
        </>
      )}

      {showQr && (
        <div
          className={`${
            lite ? "dark:bg-popoverBackgroundDark" : "dark:bg-primaryDarkColor"
          } shadow-2xl flex bg-primaryColor dark:bg-popoverBackgroundDark rounded-[2vh] z-50 justify-center items-center`}
        >
          <QR
            item={qrData.address}
            activeChain={qrData.chain}
            networkItems={allChains as (ChainAddressData & { logo: string })[]}
            logo={qrData.chain?.logo || ""}
            liteMode={lite}
          />
        </div>
      )}
    </div>
  );
};

export default Accounts;
