export enum Web3Platform {
  METAMASK = "metamask",
  WALLETCONNECT = "walletconnect",
  RABBYWALLET = "rabbywallet",
  PHANTOMWALLET = "phantomwallet",
  KEPLRWALLET = "keplrwallet",
  METAMASK_DUMMY = "metamask_dummy",
  PHANTOM_DUMMY = "phantom_dummy",
}

export const Web3PlatformNames: Record<Web3Platform, string> = {
  [Web3Platform.METAMASK]: "MetaMask",
  [Web3Platform.WALLETCONNECT]: "WalletConnect",
  [Web3Platform.RABBYWALLET]: "Rabby Wallet",
  [Web3Platform.PHANTOMWALLET]: "Phantom Wallet",
  [Web3Platform.KEPLRWALLET]: "Keplr Wallet",
  [Web3Platform.METAMASK_DUMMY]: "MetaMask",
  [Web3Platform.PHANTOM_DUMMY]: "MetaMask",
};
