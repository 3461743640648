import { capitalize } from "lodash";
import { useCallback, useState } from "react";
import { createPortal } from "react-dom";
import { formatCurrency, TriaSwapRoute } from "../../../../rx.core";

interface TokenData {
  symbol: string;
  logoUrl?: string;
  name?: string;
}

interface ChainData {
  name: string;
  logo?: string;
}

interface TokenWithChainProps {
  tokenData: TokenData;
  chainData: ChainData;
  size?: string;
}

interface SwapRouteSwitchProps {
  routes: TriaSwapRoute[];
  selected: TriaSwapRoute | undefined;
  onSelect: (item: TriaSwapRoute) => void;
}

const TokenWithChain = ({
  tokenData,
  chainData,
  size = "28px",
}: TokenWithChainProps) => (
  <div className="relative">
    {tokenData.logoUrl && (
      <img
        alt={`${tokenData.symbol}-logo`}
        className="rounded-full"
        src={tokenData.logoUrl}
        style={{ width: size, height: size }}
      />
    )}
    {chainData.logo && (
      <div className="absolute -bottom-1 -right-1 w-4 h-4 rounded border border-white/20 backdrop-blur">
        <img
          alt={`${chainData.name}-logo`}
          className="w-full h-full rounded"
          src={chainData.logo}
        />
      </div>
    )}
  </div>
);

export const SwapRouteSwitch = ({
  routes,
  selected,
  onSelect,
}: SwapRouteSwitchProps): JSX.Element => {
  const [showRouteSwitcher, setShowRouteSwitcher] = useState<boolean>(false);

  const onClose = useCallback(() => {
    setShowRouteSwitcher(false);
  }, []);

  return (
    <div className="w-full">
      {selected && (
        <button
          onClick={() => setShowRouteSwitcher(true)}
          className="w-full py-4 pl-6 pr-4 flex flex-col gap-2 font-montserrat font-semibold text-sm 
                     border border-gray-200 dark:border-[#303030]
                     bg-white dark:bg-[#151515]
                     rounded-[18px] transition-colors 
                     hover:bg-gray-50 dark:hover:bg-[#1A1A1A]"
        >
          <div className="text-gray-400 dark:text-white/40 text-xs">
            Pay using
          </div>
          <div className="flex items-center w-full">
            <div className="flex items-start gap-2">
              {selected.fromTokenData.logoUrl && (
                <TokenWithChain
                  tokenData={selected.fromTokenData}
                  chainData={selected.fromChainData}
                />
              )}
            </div>
            <div className="ml-4">
              <div className="text-gray-900 dark:text-[#FAFAFA] text-base font-semibold">
                {selected.fromTokenData.symbol}
              </div>
              {selected.fromAmountInUsd && (
                <div className="text-gray-700 dark:text-neutral-50 font-semibold">
                  {formatCurrency(selected.fromAmountInUsd, false)}
                </div>
              )}
            </div>
            <div className="ml-auto flex items-center gap-4">
              {selected?.executionDuration && (
                <div className="flex items-center gap-2">
                  <img
                    alt="time"
                    src="/icons/clock.svg"
                    className="w-[22px] h-[22px]"
                  />
                  <span className="text-gray-600 dark:text-neutral-300 text-sm">
                    {(selected.executionDuration / 60).toFixed(2)}m
                  </span>
                </div>
              )}
              <img
                alt="expand"
                src="/icons/arrow-up4.svg"
                className="w-5 h-5"
              />
            </div>
          </div>
        </button>
      )}

      {showRouteSwitcher &&
        createPortal(
          <div className="fixed inset-0 z-[1001] flex items-center justify-center">
            <div
              className="absolute inset-0 bg-black/5 dark:bg-black/20 backdrop-blur-sm"
              onClick={onClose}
            />
            <div
              className="m-4 relative w-full max-h-[80%] overflow-hidden 
                          bg-white dark:bg-[#101010] 
                          border border-gray-200 dark:border-[rgba(128,128,128,0.05)]
                          rounded-[20px] shadow-lg dark:shadow-2xl"
            >
              <div className="flex flex-col h-full">
                <div className="p-4 flex justify-between items-center border-b border-gray-100 dark:border-gray-800">
                  <h2 className="text-gray-900 dark:text-neutral-50 text-base font-montserrat font-semibold">
                    Select route
                  </h2>
                  <button
                    onClick={onClose}
                    className="w-8 h-8 flex items-center justify-center"
                  >
                    <img
                      alt="close"
                      src="/icons/close-circle-dark.svg"
                      className="w-full h-full"
                    />
                  </button>
                </div>

                <div className="pb-2 px-3 overflow-y-auto flex-1 space-y-3">
                  {routes?.map((route, index) => {
                    const provider =
                      route.providerName || capitalize(route.provider);
                    return (
                      <button
                        key={route.id}
                        onClick={() => {
                          onSelect(route);
                          onClose();
                        }}
                        className={`w-full text-left p-4 
                                  rounded-[20px] transition-all
                                  border border-gray-200 dark:border-[#303030]
                                  bg-white dark:bg-[#151515]
                                  hover:bg-gray-50 dark:hover:bg-[#1A1A1A]
                                  ${
                                    route.id === selected?.id
                                      ? "border-gray-300 dark:border-white/30 bg-gray-50 dark:bg-[#1A1A1A]"
                                      : ""
                                  }`}
                      >
                        <div className="flex flex-col gap-4">
                          <div className="flex items-start gap-4">
                            <TokenWithChain
                              tokenData={route.fromTokenData}
                              chainData={route.fromChainData}
                            />
                            <div>
                              {route.fromAmountInUsd && (
                                <div className="text-gray-900 dark:text-neutral-50 text-base font-semibold">
                                  {formatCurrency(route.fromAmountInUsd, false)}
                                </div>
                              )}
                              <div className="flex items-center gap-2">
                                <span className="text-gray-600 dark:text-white/60 text-base font-montserrat font-medium">
                                  {route.fromTokenData.symbol}
                                </span>
                                <span className="text-gray-400 dark:text-neutral-500 text-xl font-medium">
                                  •
                                </span>
                                <span className="text-gray-500 dark:text-neutral-500 text-sm font-montserrat font-semibold">
                                  {provider}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="flex justify-between items-center">
                            <div className="flex gap-2">
                              {route.tags?.map((tag) => (
                                <span
                                  key={tag}
                                  className="px-4 py-1 text-sm border 
                                           border-gray-300 dark:border-zinc-500 
                                           text-gray-600 dark:text-neutral-500
                                           rounded-full font-montserrat font-semibold"
                                >
                                  {tag}
                                </span>
                              ))}
                              {!route.tags && index !== 0 && (
                                <span
                                  className="px-4 py-1 text-sm border 
                                               border-gray-300 dark:border-zinc-500 
                                               text-gray-600 dark:text-neutral-500
                                               rounded-full font-montserrat font-semibold"
                                >
                                  Low cost
                                </span>
                              )}
                            </div>

                            <div className="flex items-center gap-4">
                              {route.gasFeeInUsd && !route.hideGasFee && (
                                <div className="flex items-center gap-2">
                                  <img
                                    alt="gas"
                                    src="/icons/gas-station.svg"
                                    className="w-[22px] h-[22px]"
                                  />
                                  <span className="text-sm text-gray-600 dark:text-neutral-300">
                                    {formatCurrency(
                                      route.gasFeeInUsd,
                                      false,
                                      "usd"
                                    )}
                                  </span>
                                </div>
                              )}
                              {route?.executionDuration && (
                                <div className="flex items-center gap-2">
                                  <img
                                    alt="time"
                                    src="/icons/clock.svg"
                                    className="w-[22px] h-[22px]"
                                  />
                                  <span className="text-sm text-gray-600 dark:text-neutral-300">
                                    {(route.executionDuration / 60).toFixed(2)}m
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>,
          document.body
        )}
    </div>
  );
};

// /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

// import { useCallback, useState } from "react";
// import { formatCurrency, TriaSwapRoute } from "../../../../rx.core";
// import { createPortal } from "react-dom";
// import { capitalize } from "lodash";

// interface SwapRouteSwitchProps {
//   routes: TriaSwapRoute[];
//   selected: TriaSwapRoute | undefined;
//   onSelect: (item: TriaSwapRoute) => void;
// }

// export const SwapRouteSwitch = ({
//   routes,
//   selected,
//   onSelect,
// }: SwapRouteSwitchProps): JSX.Element => {
//   const [showRouteSwitcher, setShowRouteSwitcher] = useState<boolean>(false);

//   const onClose = useCallback(() => {
//     setShowRouteSwitcher(false);
//   }, []);

//   return (
//     <div className="w-full">
//       {selected && (
//         <div
//           className="py-4 pl-6 pr-4 flex gap-2 flex-col font-montserrat leading-tight font-semibold text-sm border-1 border-white/30 bg-[#191919] rounded-[18px] cursor-pointer"
//           onClick={() => setShowRouteSwitcher(true)}
//         >
//           <div className="text-white/40 text-xs">Pay using</div>
//           <div className="flex items-center">
//             <div className="justify-start items-start gap-2 flex">
//               {selected.fromTokenData.logoUrl && (
//                 <div className="relative">
//                   {selected.fromTokenData.logoUrl && (
//                     <img
//                       alt="toToken-logo"
//                       className="w-[28px] h-[28px] rounded-[24px]"
//                       src={selected.fromTokenData.logoUrl}
//                     />
//                   )}
//                   {selected.fromChainData.logo && (
//                     <div className="w-[14px] h-[14px] left-[20px] top-[20px] absolute rounded-[2.88px]  border-white backdrop-blur-[1.80px]">
//                       <img
//                         alt="toChainData-logo"
//                         className="w-[12px] h-[12px] border rounded"
//                         src={selected.fromChainData.logo}
//                       />
//                     </div>
//                   )}
//                 </div>
//               )}
//               {/* {selected.toTokenData.logoUrl && (
//                 <div className="relative">
//                   {selected.toTokenData.logoUrl && (
//                     <img
//                       alt="toToken-logo"
//                       className="w-[32px] h-[32px] rounded-[32px]"
//                       src={selected.toTokenData.logoUrl}
//                     />
//                   )}
//                   {selected.toChainData.logo && (
//                     <div className="w-[12px] h-[12px] left-[20px] top-[20px] absolute rounded-[2.88px]  border-white backdrop-blur-[1.80px]">
//                       <img
//                         alt="toChainData-logo"
//                         className="w-[12px] h-[12px] border rounded"
//                         src={selected.toChainData.logo}
//                       />
//                     </div>
//                   )}
//                 </div>
//               )} */}
//             </div>
//             <div className="ml-4 text-[#FAFAFA] text-base font-semibold">
//               {selected.fromTokenData.symbol}
//               <div>
//                 {selected.fromAmountInUsd && (
//                   <div className="text-center dark:text-neutral-50 text-fontPrimaryColor  md:text-xl text-sm font-semibold font-montserrat  tracking-wide whitespace-nowrap ">
//                     {formatCurrency(selected.fromAmountInUsd, false)}
//                   </div>
//                 )}
//               </div>
//             </div>
//             <div className="ml-auto flex items-center justify-center gap-2">
//               {selected?.executionDuration && (
//                 <div className="justify-start items-center gap-[.96vh] flex">
//                   <div className="w-[2.16vh] h-[2.16vh] relative">
//                     <div className="w-[2.16vh] h-[2.16vh] left-0 top-0 absolute">
//                       <img
//                         alt="time"
//                         className="dark:visible invisible w-0 dark:w-[22px] "
//                         src="/icons/clock.svg"
//                       />
//                       <img
//                         alt="time"
//                         className="dark:invisible visible dark:w-0 "
//                         src="/icons/clock-light.svg"
//                       />
//                     </div>
//                   </div>
//                   <div className="text-center dark:text-neutral-300 text-fontLightColor text-opacity-60 md:text-sm text-xs font-medium font-montserrat whitespace-nowrap">
//                     {(selected.executionDuration / 60).toFixed(2)}m
//                   </div>
//                 </div>
//               )}
//               <div className="w-[18px] h-[18px] flex items-center justify-center">
//                 <img alt="up" src="/icons/arrow-up4.svg" />
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//       {showRouteSwitcher &&
//         createPortal(
//           <div className="h-full w-full absolute top-0 z-1001 flex items-center">
//             <div className="backdrop-blur-sm h-full w-full z-1 absolute" />
//             <div className="m-4 relative h-[80%] w-full overflow-hidden rounded-[20px] shadow -2 -zinc-500 -opacity-60 z-2 border-2 border-[rgba(128,128,128,0.05)] bg-[#101010] ">
//               <div className="w-full rounded dark:bg-stone-95 flex-col justify-end items-center gap-[.96vh] inline-flex h-full overflow-hidden">
//                 <div className="self-stretch pt-3 rounded-tl-[20px] rounded-tr-[20px]  flex-col justify-center items-center gap-[.96vh] flex">
//                   <div className="w-full px-5 py-3 flex justify-between items-center">
//                     <div className="text-center dark:text-neutral-50 text-fontPrimaryColor md:text-base text-sm  font-semibold  leading-tight font-montserrat">
//                       Select route
//                     </div>
//                     <div className="w-[3.36vh] h-[3.36vh] relative">
//                       <div
//                         className="w-[3.36vh] h-[3.36vh] left-[0.01px] top-0 absolute cursor-pointer"
//                         onClick={onClose}
//                       >
//                         <img
//                           alt="close"
//                           className="dark:visible invisible w-0 dark:w-[3.36vh]"
//                           src="/icons/close-circle-dark.svg"
//                         />
//                         <img
//                           alt="close"
//                           className="dark:invisible visible dark:w-0"
//                           src="/icons/close-circle.svg"
//                         />
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="pb-2 flex-col justify-cente flex w-full overflow-x-hidden px-3 gap-3 overflow-y-auto flex-[1_1_auto]">
//                   {routes?.length > 0 &&
//                     routes?.map((route, index) => {
//                       const provider =
//                         route.providerName || capitalize(route.provider);
//                       return (
//                         <div
//                           className={`cursor-pointer w-full border-1 border-[#303030] bg-[#151515] rounded-[20px] dark:bg-hoverSwap2 hover:bg-[#1A1A1A] ${
//                             route.id === selected?.id
//                               ? "border-2 border-[#E0E0E0] dark:border-[#252525] bg-[#1A1A1A]"
//                               : ""
//                           } flex-col justify-center items-center gap-[2.88vh] inline-flex px-5 py-3`}
//                           key={route.id}
//                           onClick={() => {
//                             onSelect(route);
//                             onClose();
//                           }}
//                         >
//                           <div className="w-full flex-col justify-start items-start gap-6 flex">
//                             <div className="justify-start items-start gap-4 flex">
//                               {route.fromTokenData.logoUrl && (
//                                 <div className="relative">
//                                   {route.fromTokenData.logoUrl && (
//                                     <img
//                                       alt="toToken-logo"
//                                       className="w-[28px] h-[28px] rounded-[32px]"
//                                       src={route.fromTokenData.logoUrl}
//                                     />
//                                   )}
//                                   {route.fromChainData.logo && (
//                                     <div className="w-[12px] h-[12px] left-[20px] top-[20px] absolute rounded-[2.88px]  border-white backdrop-blur-[1.80px]">
//                                       <img
//                                         alt="toChainData-logo"
//                                         className="w-[12px] h-[12px] border rounded"
//                                         src={route.fromChainData.logo}
//                                       />
//                                     </div>
//                                   )}
//                                 </div>
//                               )}
//                               {/* {route.toTokenData.logoUrl && (
//                               <div className="relative">
//                                 {route.toTokenData.logoUrl && (
//                                   <img
//                                     alt="toToken-logo"
//                                     className="w-[28px] h-[28px] rounded-[32px]"
//                                     src={route.toTokenData.logoUrl}
//                                   />
//                                 )}
//                                 {route.toChainData.logo && (
//                                   <div className="w-[12px] h-[12px] left-[20px] top-[20px] absolute rounded-[2.88px]  border-white backdrop-blur-[1.80px]">
//                                     <img
//                                       alt="toChainData-logo"
//                                       className="w-[12px] h-[12px] border rounded"
//                                       src={route.toChainData.logo}
//                                     />
//                                   </div>
//                                 )}
//                               </div>
//                             )} */}

//                               <div className="self-stretch flex-col justify-center items-start gap-[1.44vh] inline-flex">
//                                 <div className="flex-col justify-center items-start flex">
//                                   {route.fromAmountInUsd && (
//                                     <div className="justify-start items-center inline-flex">
//                                       <div className="text-center dark:text-neutral-50 text-fontPrimaryColor  md:text-xl text-base font-semibold font-montserrat  tracking-wide whitespace-nowrap ">
//                                         {formatCurrency(
//                                           route.fromAmountInUsd,
//                                           false
//                                         )}
//                                       </div>
//                                     </div>
//                                   )}
//                                   <div className="justify-start items-center gap-1 inline-flex">
//                                     <div className="text-white/60 text-base font-montserrat font-medium">
//                                       {route.fromTokenData.symbol}
//                                     </div>
//                                     <div className=" text-neutral-500 text-opacity-60 text-xl font-medium font-montserrat items-center flex">
//                                       •
//                                     </div>
//                                     {provider && (
//                                       <div className="text-center dark:text-neutral-500 text-fontLightColor text-opacity-60 text-[14px] font-montserrat font-semibold  leading-[1.7vh]">
//                                         {provider}
//                                       </div>
//                                     )}
//                                   </div>
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="w-full justify-between items-center inline-flex">
//                               <div className="pr-[1.44vh] py-[.48vh] rounded-[48px] -zinc-500 -opacity-10 justify-center  dark:border-zinc-500 items-center gap-[.96vh] inline-flex">
//                                 {route.tags?.map((el) => (
//                                   <div className="text-center dark:text-neutral-500 text-fontLightColor text-opacity-60 dark:border-zinc-500 border rounded-[48px] px-[1.44vh] py-[.48vh]  text-[1.6vh] font-montserrat font-semibold  leading-[1.7vh]">
//                                     {el}
//                                   </div>
//                                 ))}
//                                 {/* {!route.tags && index === 0 && (
//                                   <div className="text-center dark:text-neutral-500 text-fontLightColor text-opacity-60 dark:border-zinc-500 border rounded-[48px] px-[1.44vh] py-[.48vh]  text-[1.6vh] font-montserrat font-semibold  leading-[1.7vh]">
//                                     Recommended
//                                   </div>
//                                 )} */}
//                                 {!route.tags && index !== 0 && (
//                                   <div className="text-center dark:text-neutral-500 text-fontLightColor text-opacity-60 dark:border-zinc-500 border rounded-[48px] px-[1.44vh] py-[.48vh]  text-[1.6vh] font-montserrat font-semibold  leading-[1.7vh]">
//                                     Low cost
//                                   </div>
//                                 )}
//                               </div>
//                               <div className="justify-start items-center gap-[1.92vh] flex">
//                                 {route.gasFeeInUsd && !route.hideGasFee && (
//                                   <div className="justify-start items-center gap-[.96vh] flex">
//                                     <div className="w-[2.16vh] h-[2.16vh] relative">
//                                       <div className="w-[2.16vh] h-[2.16vh] left-0 top-0 absolute">
//                                         <img
//                                           alt="gas"
//                                           className="dark:visible invisible w-0 dark:w-[22px] "
//                                           src="/icons/gas-station.svg"
//                                         />
//                                         <img
//                                           alt="gas"
//                                           className="dark:invisible visible dark:w-0 "
//                                           src="/icons/gas-station-light.svg"
//                                         />
//                                       </div>
//                                     </div>
//                                     <div className="text-center dark:text-neutral-300 text-fontLightColor text-opacity-60 md:text-sm text-xs font-medium font-montserrat ">
//                                       {formatCurrency(
//                                         route.gasFeeInUsd,
//                                         false,
//                                         "usd"
//                                       )}
//                                     </div>
//                                   </div>
//                                 )}
//                                 {route?.executionDuration && (
//                                   <div className="justify-start items-center gap-[.96vh] flex">
//                                     <div className="w-[2.16vh] h-[2.16vh] relative">
//                                       <div className="w-[2.16vh] h-[2.16vh] left-0 top-0 absolute">
//                                         <img
//                                           alt="time"
//                                           className="dark:visible invisible w-0 dark:w-[22px] "
//                                           src="/icons/clock.svg"
//                                         />
//                                         <img
//                                           alt="time"
//                                           className="dark:invisible visible dark:w-0 "
//                                           src="/icons/clock-light.svg"
//                                         />
//                                       </div>
//                                     </div>
//                                     <div className="text-center dark:text-neutral-300 text-fontLightColor text-opacity-60 md:text-sm text-xs font-medium font-montserrat whitespace-nowrap">
//                                       {(route.executionDuration / 60).toFixed(
//                                         2
//                                       )}
//                                       m
//                                     </div>
//                                   </div>
//                                 )}
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       );
//                     })}
//                 </div>
//               </div>
//             </div>
//           </div>,
//           document.body
//         )}
//     </div>
//   );
// };
