import { AssetForTriaName } from "../package/rx.core";
import { ZeroAddressMap } from "./address-utils";

export interface ToToken {
  name: string;
  symbol?: string;
  logoUrl?: string;
  chainName: string;
  chainLogo?: string;
  chainId: number;
  tokenAddress?: string;
  decimal: number;
  isNative: boolean;
}

export const bestPathSkyNetToken = {
  chainId: 11,
  address: "0xB9E1cF44b430626A9A344f25853Ef29B63DFD7c5",
  symbol: "XCTERC20",
  name: "XCTERC20",
  decimals: 18,
  priceUSD: "0",
  coinKey: "XCTERC20",
  logoURI:
    "https://resistance-scan.skynet.io/_next/image?url=https%3A%2F%2Ff005.backblazeb2.com%2Ffile%2Ftracehawk-prod%2Flogo%2FSkynet%2FLight.png&w=75&q=75",
};

const BestPathSupportedFromTokens = [
  "0xa23a347efFC523Fd9036633c2374E8935948ca1E",
  // "0xB9E1cF44b430626A9A344f25853Ef29B63DFD7c5",
];

export const isBestPathFromToken = ({
  fromToken,
}: {
  fromToken: AssetForTriaName | undefined;
}) => {
  if (fromToken && fromToken.tokenAddress) {
    return BestPathSupportedFromTokens.map((el) => el.toLowerCase()).includes(
      (fromToken.tokenAddress as string)?.toLowerCase()
    );
  }
  if (fromToken && fromToken.isNativeToken) {
    const tokenAddress =
      ZeroAddressMap[
        `${fromToken?.chainName?.toUpperCase()}_${fromToken?.symbol?.toUpperCase()}` ||
          "ETH"
      ];
    return (
      tokenAddress &&
      BestPathSupportedFromTokens.map((el) => el.toLowerCase()).includes(
        tokenAddress?.toLowerCase()
      )
    );
  }
  return false;
};

export const isBestPathEnabled = ({
  fromToken,
  toToken,
}: {
  fromToken: AssetForTriaName | undefined;
  toToken: ToToken | undefined;
}) => {
  if (!fromToken || !toToken) {
    return false;
  }
  if (!isBestPathFromToken({ fromToken })) {
    return false;
  }
  if (
    toToken.tokenAddress?.toLowerCase() !==
    bestPathSkyNetToken.address.toLowerCase()
  ) {
    return false;
  }
  return true;
};
