import { NftsItemDetails } from "../../../../rx.core";

interface Props {
  nftImage?: string;
  nftName?: string;
  amount?: number;
  liteMode?: boolean;
}

export const NftDetailImageCard: React.FC<Props> = ({
  nftImage,
  nftName,
  amount,
  liteMode,
}) => {
  return (
    <>
      <div
        className={`${
          liteMode ? "w-[100vw]" : "w-[55vh]"
        }  h-[46.5vh] py-[2vh] rounded-[2vh] flex-col justify-center items-center gap-[3.8vh] inline-flex relative`}
      >
        <div
          className={`${
            liteMode ? "w-1/2 h-1/2" : " left-[11vh] w-[34vh] h-[45vh]"
          } nft-background-blur -z-10  shadow-lg p-[1.2vh] rounded-[4.76vh] absolute top-[8vh]`}
        >
          <img className="w-full h-full rounded-md" src={nftImage} />
        </div>

        <div
          className={`${
            liteMode ? "w-1/2" : "w-[31.2vh] h-[41.67vh]"
          }  p-[1.37vh] left-0 top-0 bg-primaryColor dark:bg-primaryDarkColor rounded-[1.67vh] border-[0.2vh] border-outlining dark:border-outliningDark flex-col justify-center relative items-center gap-[1.37vh] inline-flex z-20`}
        >
          <img
            className={`${
              liteMode ? "w-full h-full" : "w-[29vh] h-[33vh]"
            }  rounded-[0.7vh]`}
            src={nftImage}
          />

          <div
            className={`${
              liteMode ? "text-sm" : "py-[1.37vh] text-[2.1vh]"
            } self-stretch  rounded-[2.73vh] justify-start items-center gap-[2vh] inline-flex`}
          >
            <div className="justify-between items-center gap-[1.37vh] flex w-full">
              <div className="text-start text-fontLightColor dark:text-fontPrimaryDarkColor font-semibold leading-normal">
                {nftName}
              </div>
              <div className="text-fontLightColor dark:text-fontLightColorDark font-[600]">
                x{amount}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
