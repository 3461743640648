import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  updateSenderAvatar,
  updateUserAvatar,
  sendDisconnectMessage,
} from "../../../../rx.core";
import { useTriaUser } from "../../contexts";
import { UserAvatar } from "../Containers/user/UserAvatar";
import { useNavigate } from "react-router-dom";
import { useUserPrefs } from "../../../../../hooks";
import ToggleSwitch from "../Buttons/ToggleSwitch";

interface UserMenuProps {
  lite?: boolean;
}

export const UserMenu: React.FC<UserMenuProps> = ({ lite = false }) => {
  const { updateAvatar } = useTriaUser();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [accessToken, setAccessToken] = useState<string>();
  const { data } = useUserPrefs();

  useEffect(() => {
    const storedData = localStorage.getItem("tria.accessToken");
    if (storedData !== null) {
      setAccessToken(storedData);
    } else {
      console.log("Can't find triaName;");
    }
  }, []);

  const handleAvatarChangeClick = async () => {
    if (accessToken) {
      try {
        setIsLoading(true);
        const response = await updateAvatar(accessToken);
        if (response?.avatar) {
          dispatch(updateUserAvatar(response));
          dispatch(updateSenderAvatar(response));
          if (window.parent) {
            window.parent.postMessage(
              {
                type: "PROFILE_PHOTO_UPDATED",
                payload: { message: true },
              },
              "*"
            );
          }
        }
      } catch (error) {
        console.error(error);
        const { message } = error as Error;
        return Promise.reject(String(message || error));
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleCustomChainClick = () => {
    navigate("/customchain");
  };

  const handleCustomTokenClick = () => {
    navigate("/customtoken");
  };
  const handleCustomNftClick = () => {
    navigate("/customnft");
  };

  const handleSwapPreferencesClick = () => {
    navigate("/swappreferences");
  };

  const handleSignOut = () => {
    sendDisconnectMessage();
  };

  const overRideFeatureFlag = useMemo(() => {
    const walletStore = localStorage.getItem("tria.wallet.store.app");
    if (!walletStore) return undefined;
    return JSON.parse(walletStore)?.overRideWalletFeatureFlags;
  }, []);

  if (lite) {
    return (
      <div className="flex flex-col w-[200px] p-2 bg-primaryColor dark:bg-popoverBackgroundDark rounded-lg overflow-hidden">
        <div
          className="flex justify-between rounded-lg p-2 items-center gap-[2vh] self-stretch cursor-pointer hover:bg-hoverColor dark:hover:bg-popoverHoverDark"
          onClick={handleAvatarChangeClick}
        >
          <p className="flex text-start text-xs font-[600] leading-[120%] text-fontPrimaryColor dark:text-fontPrimaryDarkColor">
            Change Avatar
          </p>
          <UserAvatar width="28px" height="28px" loading={isLoading} />
        </div>
        <div
          className="flex justify-between rounded-lg p-2 items-center gap-[2vh] self-stretch cursor-pointer hover:bg-hoverColor dark:hover:bg-popoverHoverDark"
          onClick={handleSignOut}
        >
          <p className="flex text-start text-xs font-[600] leading-[120%] text-red-500">
            Sign Out
          </p>
          <img
            src="/icons/logout-red.svg"
            height={24}
            width={24}
            alt="logout"
            className="w-6 h-6"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-[38vh] bg-primaryColor dark:bg-primaryDarkColor rounded-[2.4vh] overflow-hidden">
      <div
        className="flex px-[2.4vh] py-[1.4vh] items-center gap-[2vh] self-stretch h-[8vh] cursor-pointer hover:bg-hoverColor dark:hover:bg-hoverDarkColor"
        onClick={handleAvatarChangeClick}
      >
        <div className="w-[26.7vh] flex flex-col items-start justify-start">
          <p className="flex text-start text-[2vh] font-[600] leading-[120%] text-fontPrimaryColor dark:text-fontPrimaryDarkColor">
            Change Avatar
          </p>
          <p className="flex text-center text-[1.7vh] font-[600] leading-[120%] text-fontLightColor dark:text-fontLightColorDark">
            choose your avatar
          </p>
        </div>
        <UserAvatar loading={isLoading} />
      </div>

      <div className="flex px-[2.4vh] py-[1.4vh] items-center gap-[2vh] self-stretch h-[8vh] cursor-pointer hover:bg-hoverColor dark:hover:bg-hoverDarkColor">
        <div className="w-[26.7vh] flex flex-col items-start justify-start">
          <p className="flex text-start text-[2vh] font-[600] leading-[120%] text-fontPrimaryColor dark:text-fontPrimaryDarkColor">
            Testnets
          </p>
          <p className="flex text-center text-[1.7vh] font-[600] leading-[120%] text-fontLightColor dark:text-fontLightColorDark">
            enable/disable testnets
          </p>
        </div>
        <ToggleSwitch />
      </div>

      <div
        className="flex px-[2.4vh] py-[1.4vh] items-center gap-[2vh] self-stretch h-[8vh] cursor-pointer hover:bg-hoverColor dark:hover:bg-hoverDarkColor"
        onClick={handleCustomChainClick}
      >
        <div className="w-[26.7vh] flex flex-col items-start justify-start">
          <p className="flex text-start text-[2vh] font-[600] leading-[120%] text-fontPrimaryColor dark:text-fontPrimaryDarkColor">
            Import Chains
          </p>
          <p className="flex text-center text-[1.7vh] font-[600] leading-[120%] text-fontLightColor dark:text-fontLightColorDark">
            add or update Custom Chains
          </p>
        </div>
      </div>

      {(data?.customChains?.length ?? 0) > 0 && (
        <div
          className="flex px-[2.4vh] py-[1.4vh] items-center gap-[2vh] self-stretch h-[8vh] cursor-pointer hover:bg-hoverColor dark:hover:bg-hoverDarkColor"
          onClick={handleCustomTokenClick}
        >
          <div className="w-[26.7vh] flex flex-col items-start justify-start">
            <p className="flex text-start text-[2vh] font-[600] leading-[120%] text-fontPrimaryColor dark:text-fontPrimaryDarkColor">
              Import Tokens
            </p>
            <p className="flex text-left text-[1.7vh] font-[600] leading-[120%] text-fontLightColor dark:text-fontLightColorDark">
              add or update Custom Tokens
            </p>
          </div>
        </div>
      )}

      <div
        className="flex px-[2.4vh] py-[1.4vh] items-center gap-[2vh] self-stretch h-[8vh] cursor-pointer hover:bg-hoverColor dark:hover:bg-hoverDarkColor"
        onClick={handleCustomNftClick}
      >
        <div className="w-[26.7vh] flex flex-col items-start justify-start">
          <p className="flex text-start text-[2vh] font-[600] leading-[120%] text-fontPrimaryColor dark:text-fontPrimaryDarkColor ">
            Import Nfts{" "}
          </p>
          <p className="flex text-left text-[1.7vh] font-[600] leading-[120%] text-fontLightColor dark:text-fontLightColorDark">
            add or update Custom Nfts
          </p>
        </div>
        {/* <ToggleSwitch /> */}
      </div>

      {overRideFeatureFlag && (
        <div
          className="flex px-[2.4vh] py-[1.4vh] items-center gap-[2vh] self-stretch h-[8vh] cursor-pointer hover:bg-hoverColor dark:hover:bg-hoverDarkColor"
          onClick={handleSwapPreferencesClick}
        >
          <div className="w-[26.7vh] flex flex-col items-start justify-start">
            <p className="flex text-start text-[2vh] font-[600] leading-[120%] text-fontPrimaryColor dark:text-fontPrimaryDarkColor">
              Transaction Preferences
            </p>
            <p className="flex text-left text-[1.7vh] font-[600] leading-[120%] text-fontLightColor dark:text-fontLightColorDark">
              Update transaction preferences
            </p>
          </div>
        </div>
      )}

      <div
        className="flex px-[2.4vh] py-[1.4vh] items-center gap-[2vh] self-stretch h-[8vh] cursor-pointer hover:bg-hoverColor dark:hover:bg-hoverDarkColor"
        onClick={handleSignOut}
      >
        <div className="w-[26.7vh] flex flex-col items-start justify-start">
          <p className="flex text-start text-[2vh] font-[600] leading-[120%] text-red-500">
            Sign Out
          </p>
        </div>
        <img src="/icons/logout-red.svg" height={24} width={24} alt="logout" />
      </div>
    </div>
  );
};
