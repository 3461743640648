import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  AssetForTriaName,
  RootState,
  TokenInfo,
  updateFetchParams,
  updateNftDetails,
  updateTokenInfo,
} from "../../../../../rx.core";
import { NftCard } from "../../Cards/NftCard";
import { AssetContainer } from "./AssetContainer";
import { BuyCryptoSearch } from "./BuyCryptoSearch";
import { useFetchAssets } from "../../../../../../hooks";

interface Props {
  expanded: boolean;
  onClick: () => void;
  liteMode?: boolean;
}

export const AssetSuggestionCard: React.FC<Props> = ({
  expanded,
  onClick: handleExpanded,
  liteMode,
}) => {
  const [showCrypto, setShowCrypto] = useState(true);
  const nftItems = useSelector((store: RootState) => store.User?.userNfts);
  const { allAssets } = useFetchAssets();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNftItemClick = (index: number) => {
    const selected = nftItems[index];
    if (selected) {
      const route = liteMode
        ? "/home/sendCrypto/nftsend?lite=true"
        : "/home/sendCrypto/nftsend";

      navigate(route, { state: { selected } });

      dispatch(
        updateNftDetails({
          nftImage: selected?.nftInfo?.content?.preview?.url,
          nftName: selected?.nftInfo?.name,
          type: selected?.nftInfo?.interface,
          tokenAddress: selected?.nftInfo?.contract_address,
          tokenId: selected?.nftInfo?.token_id,
          amount: selected?.amount,
          chainName: selected?.chainName,
          chainLogo: selected?.chainLogo,
          senderAddress: selected?.wallet?.address,
          addressType: selected?.wallet?.type,
        })
      );
    }
  };

  const handleTokenItemClick = (cryptoItem: AssetForTriaName) => {
    const payload: TokenInfo = {
      qouteRate: cryptoItem?.quoteRate,
      senderBalance: cryptoItem?.balanceInTokens,
      chainName: cryptoItem?.chainName,
      chainLogo: cryptoItem?.chainLogo,
      tokenName: cryptoItem?.symbol,
      tokenLogo: cryptoItem?.logoUrl,
      tokenAddress: cryptoItem?.tokenAddress || "",
      senderAddress: cryptoItem?.wallet?.address,
      type: cryptoItem?.wallet?.type,
      isNativeToken: cryptoItem?.isNativeToken,
      isCustomToken: cryptoItem?.isCustomToken,
    };

    dispatch(
      updateFetchParams({
        chainName: cryptoItem?.chainName,
        tokenAddress: cryptoItem?.tokenAddress?.toString(),
        isCustomToken: cryptoItem?.isCustomToken,
      })
    );
    dispatch(updateTokenInfo(payload));

    navigate(
      liteMode
        ? "/home/sendCrypto/cryptoSendPage?lite=true"
        : "/home/sendCrypto/cryptoSendPage"
    );
  };

  return (
    <div
      className={`
      bg-primaryColor dark:bg-primaryDarkColor
      transition-all duration-300 ease-in-out
      rounded-t-[2.4vh] dark:border-t-0.2 dark:border-[#202020]
      ${expanded ? "h-[59.5vh]" : ""}
    `}
    >
      <div
        className="p-[2.4vh] sticky top-0 bg-primaryColor dark:bg-primaryDarkColor z-10"
        onClick={(e) => {
          e.stopPropagation(); // Stop event propagation
          handleExpanded();
        }}
      >
        <div className="flex items-center justify-between">
          <div className="flex gap-[1.4vh]">
            <button
              onClick={() => setShowCrypto(true)}
              className={`text-[2vh] font-semibold transition-colors ${
                showCrypto
                  ? "text-fontPrimaryColor dark:text-fontPrimaryDarkColor"
                  : "text-fontLightColor dark:text-fontLightColorDark"
              }`}
            >
              {allAssets.length ? "Crypto" : "Buy Crypto"}
            </button>

            <button
              onClick={() => setShowCrypto(false)}
              className={`text-[2vh] font-semibold transition-colors ${
                !showCrypto
                  ? "text-fontPrimaryColor dark:text-fontPrimaryDarkColor"
                  : "text-fontLightColor dark:text-fontLightColorDark"
              }`}
            >
              NFTs
            </button>
          </div>

          <button
            data-toggle-button="true" // Add this attribute
            onClick={(e) => {
              e.stopPropagation(); // Stop event propagation
              handleExpanded();
            }}
            className="w-[4vh] h-[4vh] flex items-center justify-center cursor-pointer z-50"
            aria-label={expanded ? "Collapse" : "Expand"}
          >
            <div
              className={`transform transition-transform duration-300 ${
                expanded ? "" : "rotate-180"
              }`}
            >
              <img
                src="/icons/arrow-down.png"
                className="w-[2.1vh] h-[2.1vh] dark:hidden"
                alt="toggle"
              />
              <img
                src="/icons/arrow-down-dark.svg"
                className="w-[2.1vh] h-[2.1vh] hidden dark:block"
                alt="toggle"
              />
            </div>
          </button>
        </div>
      </div>

      {expanded && (
        <div className="h-[calc(59.5vh-13.1vh)] overflow-y-auto">
          {showCrypto ? (
            <div className="py-[2.4vh] space-y-4">
              {!allAssets.length ? (
                <BuyCryptoSearch />
              ) : (
                <div className="space-y-2">
                  {allAssets.map((crypto, index) => (
                    <AssetContainer
                      key={index}
                      cryptoItem={crypto}
                      onClick={handleTokenItemClick}
                    />
                  ))}
                </div>
              )}
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-[1.4vh] p-[3.6vh]">
              {nftItems.length > 0 ? (
                nftItems.map((nftItem, index) => (
                  <NftCard
                    key={index}
                    id={index}
                    nftItem={nftItem}
                    onClick={handleNftItemClick}
                  />
                ))
              ) : (
                <div className="col-span-2 flex flex-col items-center justify-center gap-[1vh] h-[38vh]">
                  <img
                    src="/images/nonftimagedark.svg"
                    className="w-[17.8vh] h-[17.8vh]"
                    alt="No NFTs"
                  />
                  <p className="font-[600] text-[1.4vh] text-fontLightColor dark:text-fontLightColorDark">
                    You have no NFTs right now
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
