var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import BackendService from "./index";
import { errorHandler } from "../utils/helper";
class IPFSService extends BackendService {
    constructor(apiUrl) {
        super(apiUrl);
    }
    upload(triaName) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.triaAxios.post("/greenfield/uploadData", {
                    triaName
                });
                console.log(data);
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    fetch(triaName) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.triaAxios.get("/greenfield/getData", {
                    params: {
                        triaName: triaName
                    }
                });
                console.log(data);
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
}
export default IPFSService;
