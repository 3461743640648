export const formatChainNames = (filterChainNames) => {
    let chainNames;
    for (let [index, chainName] of filterChainNames.entries()) {
        if (index == 0)
            chainNames = `${chainName}`;
        else
            chainNames = `${chainNames},${chainName}`;
    }
    return chainNames;
};
