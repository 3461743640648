import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CustomNftDataType,
  CustomTokenDataType,
  RootState,
  useToast,
} from "../../package/rx.core";
import { Nav, Opentria, useTriaUser } from "../../package/ui.common";
import { BackButton } from "../../package/ui.common/src/components/Buttons/BackButton";
import ChainSelectionDropdown from "../../package/ui.common/src/components/Dropdowns/ChainSelectionDropDown";
import { CustomNftData } from "@tria-sdk/core";
import { useReload } from "../../package/ui.common/src/contexts/reload/ReloadContext";
const initialNftData: CustomNftData = {
  chainId: "0",
  id: "",
  userId: "",
  createdAt: "",
  updatedAt: "",
  collectionAddress: "",
  tokenId: "",
  type: "ERC721",
};
const EditNft = () => {
  const [customNft, setCustomNft] = useState<CustomNftData>(initialNftData);
  const [selectedChainId, setSelectedChainId] = useState<number>();
  const { updateCustomNft: CustomNftUpdate, deleteCustomNft: CustomNftDelete } =
    useTriaUser();
  const navigate = useNavigate();
  const { toggleReload } = useReload();
  const { setToastData, openToastMessage, closeToastMessage } = useToast();
  const HandleClick = () => {
    navigate("/customnft");
  };
  const currentEditToken = useSelector(
    (store: RootState) => store?.userpref?.currentEditNft
  );
  const handleContractAddressChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newName = event.target.value?.trim();
    setCustomNft((prevCustomToken) => ({
      ...prevCustomToken,
      collectionAddress: newName,
    }));
  };

  const handleTokenIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value?.trim();
    setCustomNft((prevCustomToken) => ({
      ...prevCustomToken,
      tokenId: value,
    }));
  };
  const handleTokenTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value?.trim();
    setCustomNft((prevCustomToken) => ({
      ...prevCustomToken,
      type: value,
    }));
  };

  useEffect(() => {
    if (selectedChainId) {
      setCustomNft((prevCustomToken) => ({
        ...prevCustomToken,
        chainId: selectedChainId?.toString(),
      }));
    }
  }, [selectedChainId]);
  const addCustomNft = async () => {
    const accessToken = localStorage.getItem("tria.accessToken");
    if (!accessToken) {
      console.error("User not logged in!");
      return;
    }
    try {
      const response = await CustomNftUpdate(
        accessToken,
        customNft?.id,
        customNft
      );
      if (response?.success) {
        setToastData({
          title: "Token Updated Successfully",
          status: "message",
        });
        openToastMessage();
        // setIsCopied(false)
        await setTimeout(() => closeToastMessage(), 4000);
        toggleReload(true);
        navigate("/home");
      } else {
        setToastData({ title: response?.message, status: "error" });
        openToastMessage();
        // setIsCopied(false)
        setTimeout(() => closeToastMessage(), 4000);
      }
    } catch (err) {
      setToastData({ title: `${err}`, status: "error" });
      openToastMessage();
      // setIsCopied(false)
      setTimeout(() => closeToastMessage(), 4000);
    }
  };
  const deleteCustomNft = async () => {
    const accessToken = localStorage.getItem("tria.accessToken");
    try {
      if (!currentEditToken?.id) {
        console.error("NFT not found!");
        return;
      }
      const response = await CustomNftDelete(
        accessToken || "",
        currentEditToken?.id
      );
      if (response?.success) {
        setToastData({
          title: "Token Deleted Successfully",
          status: "message",
        });
        openToastMessage();
        // setIsCopied(false)
        await setTimeout(() => closeToastMessage(), 4000);
        toggleReload(true);
        navigate("/home");
      } else {
        setToastData({ title: response?.message, status: "error" });
        openToastMessage();
        // setIsCopied(false)
        setTimeout(() => closeToastMessage(), 4000);
      }
    } catch (err) {
      setToastData({ title: `${err}`, status: "error" });
      openToastMessage();
      // setIsCopied(false)
      setTimeout(() => closeToastMessage(), 4000);
    }
  };
  useEffect(() => {
    if (currentEditToken) {
      setCustomNft(currentEditToken);
      setSelectedChainId(parseFloat(currentEditToken?.chainId));
    }
  }, [currentEditToken]);
  return (
    <div>
      <div className="flex w-[53vh] h-[100vh] px-[1.4vh] items-center justify-center relative  bg-primaryColor dark:bg-primaryDarkColor ">
        <div className="w-[100%] h-[98%] py-[2vh] flex-col justify-center items-center gap-[1.4vh] inline-flex  rounded-xl font-montserrat">
          <div className="w-full grow shrink basis-0 flex-col justify-start items-center flex">
            <Nav />
            {/* <Toast /> */}
            <div className="w-full h-[6.4vh] px-[2vh] py-[1.67vh] rounded-[2.4vh] flex-col justify-start items-center gap-[1.4vh] inline-flex">
              <div className="self-stretch justify-start items-start gap-[1.6vh] flex flex-col ">
                <div className="h-[2.8vh] justify-start items-center gap-[1vh] flex">
                  <div className="w-[2vh] h-[2vh] justify-center items-center flex">
                    <div className="w-[1.67vh] h-[1.67vh] relative">
                      <BackButton onClick={HandleClick} />
                    </div>
                  </div>
                  {/* <div className="text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80 text-[2.4vh] font-semibold leading-normal">
                    {currentEditToken?.tokenSymbol}
                  </div> */}
                </div>
                <p className="text-center text-fontLightColor dark:text-fontLightColorDark text-opacity-60 text-[1.67vh]   leading-tight font-semibold tracking-tight ml-[1.5vh]">
                  Update your NFT info
                </p>
              </div>
            </div>

            <div className="flex flex-col h-[60vh] overflow-y-scroll mt-[8vh] w-full px-[2vh] gap-[4vh]">
              <div className="w-full gap-[1vh] flex flex-col">
                <p className="text-start text-fontLightColor dark:text-fontLightColorDark text-opacity-60 text-[1.67vh]   leading-[120%] font-semibold tracking-tight ">
                  Token Contract Address
                </p>
                <input
                  placeholder=""
                  className="py-[1vh] px-[2.4vh] rounded-[2.4vh] text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-[2vh] font-[400] border-[1px] border-hoverColor dark:border-hoverDarkColor dark:bg-hoverDarkColor bg-hoverColor focus:outline-none focus:border-[1px] focus:border-outlining dark:focus:border-outliningDark"
                  onChange={handleContractAddressChange}
                  value={customNft?.collectionAddress}
                />
              </div>
              <div className="w-full gap-[1vh] flex flex-col">
                <p className="text-start text-fontLightColor dark:text-fontLightColorDark text-opacity-60 text-[1.67vh]   leading-[120%] font-semibold tracking-tight ">
                  Chain
                </p>
                <ChainSelectionDropdown
                  setSelectedChainId={setSelectedChainId}
                />
              </div>
              <div className="w-full gap-[1vh] flex flex-col">
                <p className="text-start text-fontLightColor dark:text-fontLightColorDark text-opacity-60 text-[1.67vh]   leading-[120%] font-semibold tracking-tight ">
                  Token Id
                </p>
                <input
                  placeholder=""
                  className="py-[1vh] px-[2.4vh] rounded-[2.4vh] text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-[2vh] font-[400] border-[1px] border-hoverColor dark:border-hoverDarkColor dark:bg-hoverDarkColor bg-hoverColor focus:outline-none focus:border-[1px] focus:border-outlining dark:focus:border-outliningDark"
                  onChange={handleTokenIdChange}
                  value={customNft?.tokenId}
                />
              </div>
              <div className="w-full gap-[1vh] flex flex-col">
                <p className="text-start text-fontLightColor dark:text-fontLightColorDark text-opacity-60 text-[1.67vh]   leading-[120%] font-semibold tracking-tight ">
                  Token Type
                </p>
                <input
                  placeholder=""
                  className="py-[1vh] px-[2.4vh] rounded-[2.4vh] text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-[2vh] font-[400] border-[1px] border-hoverColor dark:border-hoverDarkColor dark:bg-hoverDarkColor bg-hoverColor focus:outline-none focus:border-[1px] focus:border-outlining dark:focus:border-outliningDark"
                  onChange={handleTokenTypeChange}
                  value={customNft?.type}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-[2vh] left-0 right-0 h-[11vh] w-full flex items-start justify-center gap-[1vh] py-[2vh]">
        {/* <Footer currentActive='/home' /> */}
        <button
          className="bg-red-400 dark:bg-[#404040] text-primaryColor py-[1.4vh] px-[2vh] w-[24vh] rounded-[3vh] text-[2vh] font-semibold leading-[120%] "
          onClick={deleteCustomNft}
        >
          Delete
        </button>
        <button
          className="bg-gray-200 dark:bg-white text-black dark:text-black py-[1.4vh] px-[2vh] w-[24vh] rounded-[3vh] text-[2vh] font-semibold leading-[120%] "
          onClick={addCustomNft}
        >
          Save
        </button>
      </div>
      <div className="absolute bottom-[0vh] invisible dark:visible  flex items-center justify-center left-0 right-0 w-full ">
        {/* <Footer currentActive='/home' /> */}
        <Opentria />
      </div>
    </div>
  );
};

export default EditNft;
