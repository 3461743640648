import { useLocation, useNavigate } from "react-router-dom";
import {
  AssetSuggestionCard,
  ConversationNav,
  Opentria,
} from "../../package/ui.common";
import { useFetchOnChainConversation } from "../../hooks";
import { ConversationCard } from "../../package/ui.common/src/components/Cards/ConversationCard";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Conversation,
  RootState,
  updateCurrentRoute,
} from "../../package/rx.core";
import "../../index.css";
import CryptoSend from "../../package/ui.common/src/components/LoadersPages/CryptoSend";
import { NftConversationCard } from "../../package/ui.common/src/components/Cards";

interface GroupedConversations {
  [key: string]: Conversation[];
}

export const UserConversations: React.FC = () => {
  const [triaName, setTriaName] = useState<string>();
  const [groupedConversations, setGroupedConversations] =
    useState<GroupedConversations>({});
  const [showSuggestion, setShowSuggestion] = useState<boolean>(false);
  const { fetchCoversation, isLoading } = useFetchOnChainConversation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const selectRef = useRef<HTMLDivElement>(null);
  const conversations = useSelector(
    (store: RootState) => store?.Caches?.onChainConversation
  );
  const queryParams = new URLSearchParams(location.search);
  const isLite = queryParams.get("lite") === "true";

  dispatch(updateCurrentRoute(window.location.pathname));

  const userHistory = useSelector((state: RootState) => state.User.userHistory);
  const withTriaName = useSelector(
    (store: RootState) => store.SendToken.tokenState.recipientTriaName
  );
  const withAddress = useSelector(
    (store: RootState) => store.SendToken?.tokenState?.recipientAddress
  );
  const prevPath = useSelector(
    (store: RootState) => store?.Route?.previousRoute
  );

  const formatDay = (timestamp: number) => {
    const date = new Date(timestamp);
    const today = new Date();
    const yesterday = new Date(today.setDate(today.getDate() - 1));

    if (date.toDateString() === new Date().toDateString()) {
      return "Today";
    } else if (date.toDateString() === yesterday.toDateString()) {
      return "Yesterday";
    } else {
      return date.toDateString();
    }
  };

  useEffect(() => {
    const storedData = localStorage.getItem("tria.wallet.store.app");
    if (storedData !== null) {
      const parsedData = JSON.parse(storedData);
      setTriaName(parsedData?.triaName);
    }
  }, []);

  useEffect(() => {
    try {
      if (triaName && withTriaName) {
        fetchCoversation(withTriaName, triaName);
      } else if (triaName && withAddress) {
        fetchCoversation(withAddress, triaName);
      }
    } catch {
      //
    }
  }, [fetchCoversation, triaName, withAddress, withTriaName]);

  useEffect(() => {
    if (conversations) {
      const groupedByDay = conversations.reduce(
        (acc: GroupedConversations, conversation) => {
          const dateLabel = formatDay(conversation.timestamp);
          if (!acc[dateLabel]) {
            acc[dateLabel] = [];
          }
          acc[dateLabel].push(conversation);
          return acc;
        },
        {}
      );

      Object.keys(groupedByDay).forEach((dateLabel) => {
        groupedByDay[dateLabel].sort((a, b) => a.timestamp - b.timestamp);
      });

      setGroupedConversations(groupedByDay);
    }
  }, [conversations]);

  const handleClick = () => {
    if (isLite) {
      if (prevPath) navigate(prevPath + "?lite=true");
      else {
        navigate("/home/sendcrypto?lite=true");
      }
    } else {
      if (prevPath) navigate(prevPath);
      else {
        navigate("/home/sendcrypto");
      }
    }
  };

  const handleConversationClick = (txHash: string) => {
    const selected = userHistory.find((item) => item.txnHash === txHash);
    if (selected) {
      navigate("/activity/TransactionDetailCrypto", { state: { selected } });
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (event: MouseEvent) => {
      const toggleButton = event.target as HTMLElement;
      const isToggleButton = toggleButton.closest(
        '[data-toggle-button="true"]'
      );

      if (isToggleButton) {
        return; // Do nothing if clicking the toggle button
      }

      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setShowSuggestion(false); // Outside click
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);
    return () =>
      document.removeEventListener("mousedown", checkIfClickedOutside);
  }, []);

  useEffect(() => {
    if (conversations?.length === 0) setShowSuggestion(false);
  }, [conversations]);

  return (
    <div>
      <div
        className={`${
          isLite
            ? "w-[100vw] dark:bg-primaryDarkColorLite"
            : "w-[53vh] dark:bg-primaryDarkColor p-[2vh]"
        } bg-primaryColor rounded-[2vh] h-[100vh] flex flex-col justify-between overflow-hidden relative`}
      >
        <div className="flex-none">
          <ConversationNav onClick={handleClick} liteMode={isLite} />
        </div>

        <div className="flex-grow overflow-hidden">
          {isLoading ? (
            <CryptoSend />
          ) : (
            <div className="h-full overflow-y-auto flex flex-col-reverse">
              {conversations && conversations?.length > 0 ? (
                <div className="flex flex-col-reverse space-y-reverse space-y-4 p-4">
                  {Object.entries(groupedConversations)?.map(
                    ([dateLabel, conversationsForDay]) => (
                      <div key={dateLabel} className="space-y-4">
                        <div className="text-center text-fontLightColor dark:text-fontLightColorDark text-[1.67vh] font-[600]">
                          {dateLabel}
                        </div>
                        {conversationsForDay?.map((item) => (
                          <div
                            key={item.timestamp}
                            className={`flex items-center gap-[0.5vh] ${
                              item?.tag === "Sent"
                                ? "justify-end"
                                : "justify-start"
                            }`}
                          >
                            {!item?.nft?.amount && item?.asset?.symbol ? (
                              <div className="py-[2.4vh] px-[2vh] flex bg-hoverColor dark:bg-hoverDarkColor rounded-[2vh]">
                                <ConversationCard
                                  item={item}
                                  onClick={() =>
                                    handleConversationClick(item?.txnHash || "")
                                  }
                                />
                              </div>
                            ) : item?.nft?.amount && !item?.asset?.symbol ? (
                              <div className="w-[26.2vh] bg-hoverColor dark:bg-hoverDarkColor rounded-[2vh]">
                                <NftConversationCard
                                  item={item}
                                  onClick={() =>
                                    handleConversationClick(item?.txnHash || "")
                                  }
                                />
                              </div>
                            ) : null}
                          </div>
                        ))}
                      </div>
                    )
                  )}
                </div>
              ) : (
                <div className="flex items-center justify-center h-full">
                  <div className="text-center text-fontLightColor dark:text-fontLightColorDark text-[2.1vh] font-[600]">
                    No recent activities
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        <div className={`flex-none ${isLite ? "w-[100vw]" : "w-full"}`}>
          <div className="relative" ref={selectRef}>
            <AssetSuggestionCard
              liteMode={isLite}
              expanded={showSuggestion}
              onClick={() => setShowSuggestion(!showSuggestion)}
            />
          </div>
        </div>

        <div className="flex-none w-full mb-2">
          <div className="dark:bg-primaryDarkColor h-10 flex-col justify-center items-center gap-[1vh] dark:flex rounded-b-0 dark:w-full">
            <Opentria />
          </div>
        </div>
      </div>
    </div>
  );
};
