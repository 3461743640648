import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useUserPrefs } from "../../hooks";
import { updateCurrentEditChain } from "../../package/rx.core";
import { Nav, Opentria } from "../../package/ui.common";
import { BackButton } from "../../package/ui.common/src/components/Buttons/BackButton";
import SearchCustomChain from "../../package/ui.common/src/layouts/Search/SearchCustomChain";
import { CustomChainData } from "@tria-sdk/core";
import { useMemo, useState } from "react";

const CustomChain = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const { data } = useUserPrefs();

  const HandleClick = () => {
    navigate("/home");
  };

  const handleChainClick = (chain: CustomChainData) => {
    dispatch(updateCurrentEditChain(chain));
    navigate("/editchain");
  };

  // Filter chains based on search value
  const filteredChains = useMemo(() => {
    if (!data?.customChains?.length) return [];
    if (!searchValue.trim()) return data.customChains;

    return data.customChains.filter((chain) =>
      chain.chainName.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [data?.customChains, searchValue]);

  return (
    <div>
      <div className="flex w-[53vh] h-[100vh] px-[1.4vh] items-center justify-center relative  bg-primaryColor dark:bg-primaryDarkColor ">
        <div className="w-[100%] h-[98%] py-[2vh] flex-col justify-center items-center gap-[1.4vh] inline-flex  rounded-xl font-montserrat">
          <div className="w-full grow shrink basis-0 flex-col justify-start items-center flex">
            <Nav />
            <div className="w-full h-[6.4vh] px-[2vh] py-[1.67vh] rounded-[2.4vh] flex-col justify-start items-center gap-[1.4vh] inline-flex">
              <div className="self-stretch justify-start items-start gap-[1.6vh] flex flex-col ">
                <div className="h-[2.8vh] justify-start items-center gap-[1vh] flex">
                  <div className="w-[2vh] h-[2vh] justify-center items-center flex">
                    <div className="w-[1.67vh] h-[1.67vh] relative">
                      <BackButton onClick={HandleClick} />
                    </div>
                  </div>
                  <div className="text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80 text-[2.4vh] font-semibold leading-normal">
                    Custom Chains
                  </div>
                </div>
                <SearchCustomChain
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                />
              </div>
            </div>
            <div className="w-full mt-[10vh] h-[60%] px-[2vh] flex flex-col overflow-y-scroll">
              {filteredChains.length > 0 ? (
                filteredChains.map((chain) => (
                  <div
                    key={chain.chainId}
                    className="flex bg-primaryColor dark:bg-primaryDarkColor hover:bg-hoverColor hover:dark:bg-hoverDarkColor w-full py-[2vh] gap-[2vh] rounded-[2vh] px-[2vh] items-center cursor-pointer"
                    onClick={() => handleChainClick(chain)}
                  >
                    {chain?.chainLogo ? (
                      <img
                        src={chain?.chainLogo}
                        alt={`${chain.chainName} logo`}
                        className="w-[3.4vh] h-[3.4vh]"
                      />
                    ) : (
                      <div className="rounded-[4vh] flex items-center justify-center uppercase font-semibold text-white bg-purple-500 w-[4vh] h-[4vh]">
                        <p>{chain?.chainName?.slice(0, 1)}</p>
                      </div>
                    )}
                    <p className="text-[1.87vh] font-semibold text-fontPrimaryColor dark:text-fontPrimaryDarkColor ">
                      {chain?.chainName}
                    </p>
                  </div>
                ))
              ) : (
                <p className="text-center text-fontLightColor dark:text-fontLightColorDark">
                  {searchValue ? "No chains found" : "NO CHAINS ADDED"}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="absolute -bottom-4 left-0 right-0 h-[11vh] w-full flex items-start justify-center">
        <button
          className="bg-gray-200 dark:bg-white  text-black py-[1.4vh] px-[2.4vh] w-[50vh] rounded-[3vh] text-[2vh] font-semibold leading-[120%] "
          onClick={() => navigate("/addcustomchain")}
        >
          ADD NEW CHAIN
        </button>
      </div>
      <div className="absolute bottom-[0vh] invisible dark:visible flex items-center justify-center left-0 right-0 w-full">
        <Opentria />
      </div>
    </div>
  );
};

export default CustomChain;
