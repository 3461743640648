// AuthIframe.tsx
import React, { useEffect } from "react";
interface Props {
  src: string;
}

export const AuthIframe: React.FC<Props> = ({ src }) => {
  useEffect(() => {
    const iframe = document.createElement("iframe");
    iframe.src = src;
    iframe.style.display = "none"; // Make the iframe invisible

    document.body.appendChild(iframe);

    return () => {
      if (document.body.contains(iframe)) {
        document.body.removeChild(iframe);
      }
    };
  }, [src]);

  return null;
};
