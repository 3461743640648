import axios from "axios";
export const errorHandler = (err) => {
    if (axios.isAxiosError(err)) {
        const axiosError = err;
        if (axiosError.response) {
            // Axios error with response
            const errorResponse = axiosError.response;
            return errorResponse.data;
        }
        else if (axiosError.request) {
            // Axios error without response (e.g., network error)
            return { message: "something went wrong, please try again", success: false };
        }
        else {
            // Other Axios errors
            return { message: axiosError.message, success: false };
        }
    }
    else if (err instanceof Error) {
        // Other general JavaScript errors
        return { message: err.message, success: false };
    }
    else {
        // Other types of errors
        return { message: err, success: false };
    }
};
export const filterNonEVM = (vault, chains) => {
    let accountKeys = {};
    for (let triaName of Object.keys(vault)) {
        accountKeys[triaName] = {};
        for (let commonSubname of getIntersectionOfTwoArray(chains, Object.keys(vault[triaName]))) {
            accountKeys[triaName][commonSubname] = vault[triaName][commonSubname];
        }
    }
    return accountKeys;
};
function getIntersectionOfTwoArray(array1, array2) {
    return array1.filter((value) => array2.includes(value));
}
